import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    getSuggestionCountByStatus(ctx, queryParams) {
      //console.log("courses Fetching");
      return new Promise((resolve, reject) => {
        axios
          .get('/hr/admin/getSuggestionCountByStatus', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    getInProgressSuggestionCount(ctx, queryParams) {
        //console.log("courses Fetching");
        return new Promise((resolve, reject) => {
          axios
            .get('/hr/admin/getSuggestionCountByStatus', { params: queryParams })
            .then(response => resolve(response))
            .catch(error => reject(error))
        })
      },
      getRejectedSuggestionCount(ctx, queryParams) {
        //console.log("courses Fetching");
        return new Promise((resolve, reject) => {
          axios
            .get('/hr/admin/getSuggestionCountByStatus', { params: queryParams })
            .then(response => resolve(response))
            .catch(error => reject(error))
        })
      },
      getConfirmedSuggestionCount(ctx, queryParams) {
        //console.log("courses Fetching");
        return new Promise((resolve, reject) => {
          axios
            .get('/hr/admin/getSuggestionCountByStatus', { params: queryParams })
            .then(response => resolve(response))
            .catch(error => reject(error))
        })
      },

     
    
  },
}
